import { getPublicRuntimeConfig } from '../config';
import { CommentDisplayMode } from './commentDisplayMode';
import { DiscloseScope } from './discloseScope';

export type ArticleType = 'SLIDE' | 'VIDEO';
export type ArticleStatus = 'CONVERTING' | 'DONE' | 'ERROR' | 'DELETED';

export type Article = {
  articleId: string;
  authorId: number;
  name: string | null;
  description: string | null;
  status: ArticleStatus | null;
  type: ArticleType;
  topImageM: string | null;
  topImageL: string | null;
  width: number | null;
  height: number | null;
  discloseScope: DiscloseScope;
  pageCount: number;
  sessionCount: number;
  likeCount: number; // スライド保存とページ保存の合算値
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
  createDateFormatted: string; // YYYY/MM/DD
  updateDateFormatted: string; // YYYY/MM/DD
  updateDateEpochMilliseconds: number;
  pages: ArticlePage[];
  video: ArticleVideo | null;
  highlight: string | null;
  references: string[];
  hidden: boolean;
  needLogin: boolean;
  commentDisplayMode: CommentDisplayMode;
  tableOfContents: TableOfContents[] | null;
};

export type ArticlePage = {
  articleId: string;
  pageNumber: number;
  slideText: string | null;
  linkUrl: string | null;
  imgL: string;
};

export type ArticleVideo = {
  fileName: string;
};

export type User = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  hasProfileImage: boolean;
  idBaseProfileUrl: string | null;
  updateDateEpochMilliseconds: number;
  discloseId: string | null;
};

export type Author = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  updateDateEpochMilliseconds: number;
  profilePhotoS3Key: string | null;
  idBaseProfileUrl: string | null;
};

export type TableOfContents = {
  pageRange: string;
  contents: string;
};

function getSlideWebBaseUrl() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return slideWebBaseUrl;
}

export function createArticleRelativeUrl(articleId: string) {
  return `/article/view/${articleId}`;
}

export function createArticleUrl(articleId: string) {
  return `${getSlideWebBaseUrl()}${createArticleRelativeUrl(articleId)}`;
}

export function createArticlePageRelativeUrl(
  articleId: string,
  pageNum: number,
) {
  return `${createArticleRelativeUrl(articleId)}#${pageNum}`;
}

export function createArticlePageUrl(articleId: string, pageNum: number) {
  return `${createArticleUrl(articleId)}#${pageNum}`;
}

export function createArticleEditRelativeUrl(articleId: string) {
  return `/article/edit/${articleId}`;
}

export function createArticleEmbedUrl(articleId: string) {
  return `${getSlideWebBaseUrl()}/article/embed/${articleId}`;
}

export function createArticleImageRelativeUrl(
  articleId: string,
  imageName: string | null,
  options?: {
    version?: number;
  },
) {
  if (!imageName) {
    return null;
  }
  const name = imageName.startsWith('/') ? imageName.substring(1) : imageName;
  let url = `/api/article/view-image/${articleId}/${name}`;

  if (options?.version) {
    const p = new URLSearchParams();
    p.set('v', String(options.version));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createArticleImageUrl(
  articleId: string,
  imageName: string | null,
  options?: {
    version?: number;
  },
) {
  const relativeUrl = createArticleImageRelativeUrl(
    articleId,
    imageName,
    options,
  );
  if (!relativeUrl) {
    return null;
  }
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}
